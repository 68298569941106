// alterar arquivo: public/index.html
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "2Jc5Cv0NmR0zoJKOD7mE";  // CANEVER IMIGRANTES


export const ids_firebase = [
    "2Jc5Cv0NmR0zoJKOD7mE", // Canever Imigrantes 0
    "FHS8FDsZCvVvIgT07UHL" // Canever Central 1
];

    export const href_revenda = [
        "veiculo_maverick_imigrantes",
        "veiculo_maverick_central"

    ];
    
